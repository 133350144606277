
import Vue from 'vue'
import Component from 'vue-class-component'
// import { Http } from '@/apis/Http'
import Stat from '@/apis/Stat'

@Component({
  name: 'Stat'
})

export default class AdminSuppors extends Vue {
  search='';
  statList = {
    headers: [],
    items: []
  };

  loading = false
  totalItems = 0
  currentPage = 1
  prevPage = 1
  itemsPerPage = 20
  pageCount = 1

  created() {
    // @ts-ignore
    this.$setLoading(true)
    this.getStatList()
  }

  navigate() {
    if (this.currentPage === this.prevPage) return
    // @ts-ignore
    this.$setLoading(true)
    this.getStatList()
  }

  getStatList() {
    const params = {
      limit: this.itemsPerPage,
      offset: (this.currentPage - 1) * this.itemsPerPage,
      page: this.currentPage
    }

    this.loading = true
    Stat.fetchStatList(params)
      .then(({ data }) => {
        this.statList.items = data.items
        this.statList.headers = data.headers
        // @ts-ignore
        this.$setLoading(false)

        this.totalItems = data.total
        this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
        // @ts-ignore
        this.prevPage = parseInt(this.currentPage)
      })
      .finally(() => { this.loading = false })
  }
}
