import { Http } from '@/apis/Http'
// import Csrf from '@/apis/Csrf'

export default {
  fetchStatList(params = {}) {
    return Http.get('/admin/stat', {
      params
    })
  },
  fetchStatListLc(params = {}) {
    return Http.get('/admin/statLc', {
      params
    })
  }
}
